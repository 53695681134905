// extracted by mini-css-extract-plugin
export var header = "products-module--header--2Fn3V";
export var description = "products-module--description--2KEOU";
export var subheader = "products-module--subheader--3iy2E";
export var disclaimer = "products-module--disclaimer--2uKGj";
export var background = "products-module--background--1o81X";
export var productcontainer = "products-module--productcontainer--1Nyy5";
export var poslogo = "products-module--poslogo--PFxzi";
export var logo = "products-module--logo--2BnjX";
export var logorow = "products-module--logorow--GpS9q";
export var contact = "products-module--contact--wKM8a";
export var productcover = "products-module--productcover--32ZQH";